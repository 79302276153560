<dx-toolbar class="yo-toolbar">
  <dxi-item location="before">
    <div class="d-flex flex-row align-items-center">
      <div class="mg-l-5 mg-r-5">
        <yo-button
          class="mg-r-10"
          iconClass="fa fa-plus"
          label="CRÉER"
          (onClick)="openCreationDialog()"
          tooltip="Créer un point de facturation">
        </yo-button>
      </div>
    </div>
  </dxi-item>
  <dxi-item location="after" class="mg-r-16">
    <yo-button
      iconClass="fas fa-copy"
      class="mg-r-5 va-top"
      (onClick)="openTransfertEffectivesDialog()"
      tooltip="Transferer les effectifs">
    </yo-button>
    <yo-button
      iconClass="fas fa-check"
      class="mg-r-5"
      (onClick)="validateFacture()"
      [disabled]="!canValidateOrDelete()"
      tooltip="Valider les éléments sélectionnés">
    </yo-button>
    <yo-button
      iconClass="fas fa-trash"
      buttonClass="cta-delete"
      (onClick)="deleteValues()"
      [disabled]="!canValidateOrDelete()"
      tooltip="Supprimer les éléments sélectionnés">
    </yo-button>
  </dxi-item>
</dx-toolbar>


<div class="container-table">
  <dx-data-grid
    [dataSource]="facturesClient"
    keyExpr="id"
    [height]="utilsSvc.getWindowAvailableHeight(182)"
    width="100%"
    [hoverStateEnabled]="true"
    [rowAlternationEnabled]="true"
    [allowColumnResizing]="true"
    [showRowLines]="true"
    [showBorders]="true"
    [(selectedRowKeys)]="selectedRows">
    <dxo-search-panel [visible]="true"></dxo-search-panel>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxi-column dataField="id" caption="Actions"

                [allowFiltering]="false"
                [allowGrouping]="false"
                [allowEditing]="false"
                [alignment]="'left'"
                [width]="90"
                cellTemplate="actionsCellTemplate">
    </dxi-column>
    <dxi-column dataField="statut" caption="Statut"
                [allowEditing]="false"
                [allowFiltering]="true"
                [allowSearch]="true"
                cellTemplate="statusCellTemplate">
    </dxi-column>
    <dxi-column dataField="site.libelle" caption="Site"
                [allowFiltering]="true"
                [allowGrouping]="false"
                [allowSearch]="true"
                cellTemplate="siteCellTemplate">
    </dxi-column>
    <dxi-column dataField="numeroFacture" caption="Numéro de facture"
                [allowEditing]="false"
                [allowFiltering]="true"
                [allowSearch]="true">
    </dxi-column>
    <dxi-column dataField="pointFacturation.libelle" caption="Point de facturation"
                [allowEditing]="false"
                [allowFiltering]="true"
                [allowSearch]="true"
                cellTemplate="pointFacturationCellTemplate">
    </dxi-column>
    <dxi-column dataField="montantHT" caption="Montant HT"
                [allowEditing]="false"
                [allowFiltering]="false"
                [allowSearch]="true"
                [alignment]="'left'"
                cellTemplate="totalHTCellTemplate">
    </dxi-column>
    <dxi-column dataField="montantTTC" caption="Montant TTC"
                [allowEditing]="false"
                [allowFiltering]="false"
                [allowSearch]="true"
                [alignment]="'left'"
                cellTemplate="totalTTCCellTemplate">
    </dxi-column>

    <dxi-column dataField="dateFacturation" caption="Date facturation"
                dataType="date"
                [allowFiltering]="true"
                [allowGrouping]="false"
                [allowSearch]="true"
                cellTemplate="dateTemplate">
      <dxo-header-filter [dataSource]="getDateHeaderFilterDataSource()"></dxo-header-filter>
    </dxi-column>

    <dxo-selection [allowSelectAll]="true" showCheckBoxesMode="always"
                   mode="multiple"></dxo-selection>
    <dxo-editing mode="cell" [allowUpdating]="true" [confirmDelete]="false"></dxo-editing>

    <div *dxTemplate="let cell of 'actionsCellTemplate'" class="custom-button-container" >
      <yo-button
        size="small"
        [iconClass]="'fa fa-eye'"
        buttonClass="cta-inner-edit-or-watch"
        [tooltip]="'Consulter la facture'"
        (onClick)="openDialogDetails(null, cell.row.data)">
      </yo-button>
    </div>

    <div *dxTemplate="let cell of 'siteCellTemplate'">
      <yo-site [siteId]="cell.row.data.site.id" [siteLibelleTooltip]="cell.row.data.site.libelle"
               [siteLibelle]="cell.row.data.site.libelle"></yo-site>
    </div>

    <div class="total text-center" *dxTemplate="let cell of 'pointFacturationCellTemplate'">
      {{ cell.value }}
    </div>

    <div class="total text-center" *dxTemplate="let cell of 'totalHTCellTemplate'">
      {{ cell.value.value }} {{ cell.row.data.montantHT.uniteMonetaire }}
    </div>

    <div class="total text-center" *dxTemplate="let cell of 'totalTTCCellTemplate'">
      {{ cell.value.value }} {{ cell.row.data.montantTTC.uniteMonetaire }}
    </div>

    <div *dxTemplate="let cell of 'dateTemplate'">
      {{ utilsSvc.dateToDDMMYYYY(cell?.value)}}
    </div>
    <div class="status text-center" *dxTemplate="let cell of 'statusCellTemplate'">
      <ng-container *ngIf="cell.value === 'PROFORMA'">
        <i class="fas fa-hourglass-half mg-r-15"></i>
      </ng-container>
      <ng-container *ngIf="cell.value !== 'PROFORMA'">
        <i class="fas fa-check mg-r-15"></i>
      </ng-container>
      {{ cell.value === 'PROFORMA' ? 'En attente de validation' : 'Validée' }}
    </div>




    <dxo-pager
      [showPageSizeSelector]="true"
      [showNavigationButtons]="false"
      [allowedPageSizes]="[20, 50, 100, 200]"
      [visible]="true"
      [showInfo]="true"
      infoText="{2} facture(s)">
    </dxo-pager>

  </dx-data-grid>
</div>

<dx-popup
  [showTitle]="true"
  [title]="'Création de factures'"
  [(visible)]="displayCreation"
  [width]="400"
  [height]="'auto'"
  [maxHeight]="800"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (onHiding)="closeCreationDialog()"
>
  <div class="mt-3">
    <yo-lookup
      #uniteDeProductionLookup
      [data]="allUniteDeProduction"
      [placeholder]="'Sélectionner l\'unite de production facturatice'"
    ></yo-lookup>
  </div>
  <div class="mt-3">
    <yo-tagbox
      #pointFacturationTagBox
      [data]="allPointsFacturation"
      [placeholder]="'Sélectionner un ou plusieurs point(s) de facturation'"
      maxDisplayedTags=3
    >
    </yo-tagbox>
  </div>
  <div class="mt-3">
    <yo-commande-fournisseur-date-filter
      (onValueChange)="onDateFilterValueChange($event)"
      height="45px"
      [selectedDates]="selectedPeriode"
    >
    </yo-commande-fournisseur-date-filter>
  </div>
  <div class="d-flex justify-content-end">
    <yo-button
      type="submit"
      iconClass="fas fa-save"
      buttonClass="cta-success"
      (onClick)="generateFactureClient()"
      tooltip="Enregistrer">
    </yo-button>
    <yo-button
      class="mg-l-5"
      buttonClass="cta-delete"
      (onClick)="closeCreationDialog()"
      tooltip="Fermer"
      iconClass="fas fa-times">
    </yo-button>
  </div>
</dx-popup>

<yo-facture-client-details-dialog></yo-facture-client-details-dialog>
<yo-facture-client-errors></yo-facture-client-errors>
<yo-transfert-effectives></yo-transfert-effectives>
