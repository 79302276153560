// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]7.0-rc-11257(7d679d3854)-C04/02/2025-16:34:18-B04/02/2025-16:54:01' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]7.0-rc-11257(7d679d3854)-C04/02/2025-16:34:18-B04/02/2025-16:54:01",
  branch: "master",
  latestTag: "7.0-rc",
  revCount: "11257",
  shortHash: "7d679d3854",
  longHash: "7d679d385496776cfa3ab612badc3a7fe98cbb50",
  dateCommit: "04/02/2025-16:34:18",
  dateBuild: "04/02/2025-16:54:01",
  buildType: "Ansible",
  } ;
